import React from "react";

const Discord = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="33"
            height="33"
            fill="none"
            viewBox="0 0 33 33"
        >
            <path
                fill="#fff"
                d="M24.834 8.734a19.527 19.527 0 00-4.95-1.555c-.233.428-.445.867-.634 1.316a18.123 18.123 0 00-5.484 0 13.91 13.91 0 00-.642-1.316c-1.713.296-3.38.82-4.954 1.559a20.765 20.765 0 00-3.558 13.896 19.79 19.79 0 006.07 3.111c.492-.675.927-1.39 1.3-2.137a12.743 12.743 0 01-2.048-.997c.171-.127.34-.26.502-.396a14.001 14.001 0 0012.139 0c.163.137.332.274.502.396-.655.394-1.343.728-2.057.998.372.75.807 1.466 1.3 2.142a19.768 19.768 0 006.075-3.112 20.74 20.74 0 00-3.56-13.905zM12.521 19.839a2.33 2.33 0 01-2.156-2.454 2.315 2.315 0 012.156-2.454 2.303 2.303 0 012.157 2.455 2.313 2.313 0 01-2.157 2.453zm7.971 0a2.33 2.33 0 01-2.156-2.454 2.315 2.315 0 012.156-2.456 2.3 2.3 0 012.157 2.456 2.314 2.314 0 01-2.157 2.454z"
            ></path>
        </svg>
    );
}

export default Discord;