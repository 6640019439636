import MainLayout from "@components/MainLayout";
import CrossExchange from "./components/CrossExchange";
import Hero from "./components/Hero";
import OurFeatures from "./components/OurFeatures";
import Prefooter from "./components/Prefooter";

const Home = () => {
    return <>
        <Hero />
        <MainLayout>
            <CrossExchange />
            <OurFeatures />
            <Prefooter />
        </MainLayout>
    </>;
}

export default Home;