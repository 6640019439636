import { Button, Grid, Typography } from "@mui/material";
import animation from '@assets/hypergif.gif';


const Hero = () => {

    const handleJoinClick = () => {
        window.open('https://discord.gg/9tBc53dQ', '_blank')
     };

    return (
        <Grid container sx={{
            minHeight: 'calc(100vh - 60px)',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundImage: `url(${animation})`,
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
        }}>
            <Grid container sx={{ flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                <Grid item xs={12} mb={4} sx={{ textAlign: 'center' }}>
                    <Typography variant='h1' sx={{ color: '#A7EFFF', textShadow: '0 0 10px rgba(167, 239, 255, 1)' }}>Trade Everywhere,</Typography>
                    <Typography variant='h1'>Analyze Anytime.</Typography>
                </Grid>
                <Grid item md={3} sm={6} xs={12} mb={4} sx={{ textAlign: 'center' }}>
                    <Typography variant="body1">Hypertech is the one-stop hub for trading and analytics on all your crypto exchanges.</Typography>
                </Grid>
                <Grid item md={3} xs={6}>
                    <Button
                        onClick={handleJoinClick}
                        variant="contained"
                        sx={{ borderRadius: '5px' }}
                    >Join discord</Button>
                </Grid>
            </Grid>
        </Grid>
    );
}

export default Hero;


/*
box-shadow: 0px -12px 31.2px 0px rgba(167, 239, 255, 1) inset;

box-shadow: 0px 1.45px 34.3px 0px rgba(167, 239, 255, 0.25);

box-shadow: 0px 2px 3.8px 0px rgba(167, 239, 255, 1) inset;


*/