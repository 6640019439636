import {Button, InputAdornment, OutlinedInput} from "@mui/material";
import axios from "axios";
import {useState} from "react";


const RequestAccessButton = () => {

    const [input, setInput] = useState<string>('')

    const handlePostClick = () => {
        axios.post('https://hypertech.exchange/submit/email-request', {
            email: input
        }).catch(function (error) {
            console.log(error);
        });
    };

    return (
        <OutlinedInput
            id="outlined-adornment-email"
            type={'text'}
            fullWidth
            onChange={(event) => {
                setInput(event.target.value)
            }}
            value={input}
            //size="small"
            endAdornment={
                <InputAdornment position="end">
                    <Button
                        onClick={handlePostClick}
                        variant="contained"
                        sx={{borderRadius: '5px'}}
                    >Request access</Button>
                </InputAdornment>
            }
            placeholder="Enter your email"
            sx={{
                '& .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'rgba(242, 246, 252, 0.15)'
                },
                backgroundColor: "rgba(242, 246, 252, 0.05)",
                borderRadius: '0.5rem',
                maxWidth: '400px'
                //paddingRight: '3px',
            }}
        />
    )
}

export default RequestAccessButton;
