import { Grid, Typography } from "@mui/material";
import RequestAccessButton from "@components/RequestAccessButtonn";
import Icon from "@assets/Icon";


const Prefooter = () => {
    return (
        <Grid container alignItems='center' justifyContent='center'
            sx={{ paddingX: { md: '100px' } }}
            mt={{ lg: 20, xs: 10 }}
        >
            <Grid item xs={12} md={8} mb={4} display='flex' justifyContent='center'>
                <Icon />
            </Grid>
            <Grid item xs={12} md={8} mb={4}>
                <Typography variant="h2" sx={{ textAlign: 'center' }}>Ready for a revolution in crypto trading?</Typography>
                <Typography variant="body1" sx={{ textAlign: 'center' }}>Join Hypertech and elevate your trading intelligence.</Typography>
            </Grid>
            <Grid item xs={11} md={6} display='flex' justifyContent='center'>
                <RequestAccessButton />
            </Grid>
        </Grid >
    );
};

export default Prefooter;