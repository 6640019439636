import React from "react";

const Icon = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="189"
            height="189"
            fill="none"
            viewBox="0 0 189 189"
        >
            <g filter="url(#filter0_di_1_2487)" opacity="0.2">
                <rect
                    width="153.347"
                    height="153.344"
                    x="17.605"
                    y="18.2"
                    stroke="#F2F6FC"
                    rx="35.5"
                ></rect>
            </g>
            <g filter="url(#filter1_di_1_2487)" opacity="0.5">
                <rect
                    width="124.416"
                    height="124.413"
                    x="32.07"
                    y="32.665"
                    stroke="#F2F6FC"
                    rx="20.5"
                ></rect>
            </g>
            <g filter="url(#filter2_i_1_2487)">
                <rect
                    width="96.195"
                    height="96.193"
                    x="46.18"
                    y="46.776"
                    fill="#fff"
                    fillOpacity="0.05"
                    rx="6"
                ></rect>
            </g>
            <rect
                width="97.195"
                height="97.193"
                x="45.68"
                y="46.276"
                stroke="#F2F6FC"
                strokeOpacity="0.1"
                rx="6.5"
            ></rect>
            <g
                fill="#F2F6FC"
                fillRule="evenodd"
                clipRule="evenodd"
                filter="url(#filter3_di_1_2487)"
                opacity="0.9"
            >
                <path d="M79.957 116.951H56.172v-7.355h23.785v7.355zM123.024 116.951H99.239v-7.355h23.785v7.355zM127.8 98.498H60.836v-7.356H127.8v7.356zM89.34 80.148H65.423v-7.355H89.34v7.355zM132.389 80.148h-23.915v-7.355h23.915v7.355z"></path>
            </g>
            <defs>
                <filter
                    id="filter0_di_1_2487"
                    width="188.147"
                    height="188.144"
                    x="0.205"
                    y="0.8"
                    colorInterpolationFilters="sRGB"
                    filterUnits="userSpaceOnUse"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
                    <feColorMatrix
                        in="SourceAlpha"
                        result="hardAlpha"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    ></feColorMatrix>
                    <feOffset></feOffset>
                    <feGaussianBlur stdDeviation="8.45"></feGaussianBlur>
                    <feColorMatrix values="0 0 0 0 0.654902 0 0 0 0 0.937255 0 0 0 0 1 0 0 0 0.53 0"></feColorMatrix>
                    <feBlend
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_1_2487"
                    ></feBlend>
                    <feBlend
                        in="SourceGraphic"
                        in2="effect1_dropShadow_1_2487"
                        result="shape"
                    ></feBlend>
                    <feColorMatrix
                        in="SourceAlpha"
                        result="hardAlpha"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    ></feColorMatrix>
                    <feOffset dy="1"></feOffset>
                    <feGaussianBlur stdDeviation="5.15"></feGaussianBlur>
                    <feComposite
                        in2="hardAlpha"
                        k2="-1"
                        k3="1"
                        operator="arithmetic"
                    ></feComposite>
                    <feColorMatrix values="0 0 0 0 0.654902 0 0 0 0 0.937255 0 0 0 0 1 0 0 0 1 0"></feColorMatrix>
                    <feBlend in2="shape" result="effect2_innerShadow_1_2487"></feBlend>
                </filter>
                <filter
                    id="filter1_di_1_2487"
                    width="159.216"
                    height="159.213"
                    x="14.67"
                    y="15.265"
                    colorInterpolationFilters="sRGB"
                    filterUnits="userSpaceOnUse"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
                    <feColorMatrix
                        in="SourceAlpha"
                        result="hardAlpha"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    ></feColorMatrix>
                    <feOffset></feOffset>
                    <feGaussianBlur stdDeviation="8.45"></feGaussianBlur>
                    <feColorMatrix values="0 0 0 0 0.654902 0 0 0 0 0.937255 0 0 0 0 1 0 0 0 0.53 0"></feColorMatrix>
                    <feBlend
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_1_2487"
                    ></feBlend>
                    <feBlend
                        in="SourceGraphic"
                        in2="effect1_dropShadow_1_2487"
                        result="shape"
                    ></feBlend>
                    <feColorMatrix
                        in="SourceAlpha"
                        result="hardAlpha"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    ></feColorMatrix>
                    <feOffset dy="1"></feOffset>
                    <feGaussianBlur stdDeviation="5.15"></feGaussianBlur>
                    <feComposite
                        in2="hardAlpha"
                        k2="-1"
                        k3="1"
                        operator="arithmetic"
                    ></feComposite>
                    <feColorMatrix values="0 0 0 0 0.654902 0 0 0 0 0.937255 0 0 0 0 1 0 0 0 1 0"></feColorMatrix>
                    <feBlend in2="shape" result="effect2_innerShadow_1_2487"></feBlend>
                </filter>
                <filter
                    id="filter2_i_1_2487"
                    width="98.195"
                    height="99.993"
                    x="45.18"
                    y="45.776"
                    colorInterpolationFilters="sRGB"
                    filterUnits="userSpaceOnUse"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
                    <feBlend
                        in="SourceGraphic"
                        in2="BackgroundImageFix"
                        result="shape"
                    ></feBlend>
                    <feColorMatrix
                        in="SourceAlpha"
                        result="hardAlpha"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    ></feColorMatrix>
                    <feOffset dy="1.8"></feOffset>
                    <feGaussianBlur stdDeviation="0.9"></feGaussianBlur>
                    <feComposite
                        in2="hardAlpha"
                        k2="-1"
                        k3="1"
                        operator="arithmetic"
                    ></feComposite>
                    <feColorMatrix values="0 0 0 0 0.94902 0 0 0 0 0.964706 0 0 0 0 0.988235 0 0 0 0.07 0"></feColorMatrix>
                    <feBlend in2="shape" result="effect1_innerShadow_1_2487"></feBlend>
                </filter>
                <filter
                    id="filter3_di_1_2487"
                    width="171.817"
                    height="139.758"
                    x="8.372"
                    y="24.993"
                    colorInterpolationFilters="sRGB"
                    filterUnits="userSpaceOnUse"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
                    <feColorMatrix
                        in="SourceAlpha"
                        result="hardAlpha"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    ></feColorMatrix>
                    <feOffset></feOffset>
                    <feGaussianBlur stdDeviation="23.9"></feGaussianBlur>
                    <feComposite in2="hardAlpha" operator="out"></feComposite>
                    <feColorMatrix values="0 0 0 0 0.654902 0 0 0 0 0.937255 0 0 0 0 1 0 0 0 0.59 0"></feColorMatrix>
                    <feBlend
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_1_2487"
                    ></feBlend>
                    <feBlend
                        in="SourceGraphic"
                        in2="effect1_dropShadow_1_2487"
                        result="shape"
                    ></feBlend>
                    <feColorMatrix
                        in="SourceAlpha"
                        result="hardAlpha"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    ></feColorMatrix>
                    <feOffset dy="7"></feOffset>
                    <feGaussianBlur stdDeviation="5.15"></feGaussianBlur>
                    <feComposite
                        in2="hardAlpha"
                        k2="-1"
                        k3="1"
                        operator="arithmetic"
                    ></feComposite>
                    <feColorMatrix values="0 0 0 0 0.654902 0 0 0 0 0.937255 0 0 0 0 1 0 0 0 1 0"></feColorMatrix>
                    <feBlend in2="shape" result="effect2_innerShadow_1_2487"></feBlend>
                </filter>
            </defs>
        </svg>
    );
}

export default Icon;