import { useMediaQuery, useTheme } from "@mui/material";

const useScreenMode = () => {
    const theme = useTheme();
    const isPhone = useMediaQuery(theme.breakpoints.down('sm'));
    const isTablet = useMediaQuery(theme.breakpoints.between('sm', 'md'));
    const isDesktop = useMediaQuery(theme.breakpoints.up('md'));

    return { isDesktop, isPhone, isTablet}

}

export default useScreenMode;