import { PAGE_MAX_WIDTH } from "@base/constants/constants"
import { Grid } from "@mui/material"
import { ReactElement, JSXElementConstructor, ReactNode, ReactPortal } from "react"



const MainLayout = (props: { children: string | number | boolean | ReactElement<any, string | JSXElementConstructor<any>> | Iterable<ReactNode> | ReactPortal | null | undefined }) => {
    return (
        <Grid container sx={{
            justifyContent: 'center',
        }}>
            <Grid container className="main-layout" sx={{
                maxWidth: PAGE_MAX_WIDTH,
                alignItems: 'center',
                justifyContent: 'center',
                paddingX: '1rem'
            }}>{props.children}</Grid>
        </Grid>
    )
}

export default MainLayout;