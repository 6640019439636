import React from "react";

const PortfolioOversight = () => {
    return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="56"
          height="56"
          fill="none"
          viewBox="0 0 56 56"
        >
          <g filter="url(#filter0_i_20_200)">
            <rect
              width="53.147"
              height="53.147"
              x="1.504"
              y="1.7"
              fill="url(#paint0_radial_20_200)"
              fillOpacity="0.1"
              rx="6"
            ></rect>
          </g>
          <rect
            width="54.147"
            height="54.147"
            x="1.004"
            y="1.2"
            stroke="#F2F6FC"
            strokeOpacity="0.1"
            rx="6.5"
          ></rect>
          <g
            fill="#fff"
            fillRule="evenodd"
            clipRule="evenodd"
            filter="url(#filter1_di_20_200)"
          >
            <path d="M12.937 43.415h30.28v-5.053h-30.28v5.053zM12.937 30.76h30.28v-5.054h-30.28v5.053zM12.937 18.184l30.28.001v-5.053h-30.28v5.053z"></path>
          </g>
          <defs>
            <filter
              id="filter0_i_20_200"
              width="55.147"
              height="56.147"
              x="0.504"
              y="0.7"
              colorInterpolationFilters="sRGB"
              filterUnits="userSpaceOnUse"
            >
              <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
              <feBlend
                in="SourceGraphic"
                in2="BackgroundImageFix"
                result="shape"
              ></feBlend>
              <feColorMatrix
                in="SourceAlpha"
                result="hardAlpha"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              ></feColorMatrix>
              <feOffset dy="1"></feOffset>
              <feGaussianBlur stdDeviation="0.5"></feGaussianBlur>
              <feComposite
                in2="hardAlpha"
                k2="-1"
                k3="1"
                operator="arithmetic"
              ></feComposite>
              <feColorMatrix values="0 0 0 0 0.94902 0 0 0 0 0.964706 0 0 0 0 0.988235 0 0 0 0.1 0"></feColorMatrix>
              <feBlend in2="shape" result="effect1_innerShadow_20_200"></feBlend>
            </filter>
            <filter
              id="filter1_di_20_200"
              width="50.08"
              height="50.083"
              x="3.037"
              y="3.232"
              colorInterpolationFilters="sRGB"
              filterUnits="userSpaceOnUse"
            >
              <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
              <feColorMatrix
                in="SourceAlpha"
                result="hardAlpha"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              ></feColorMatrix>
              <feOffset></feOffset>
              <feGaussianBlur stdDeviation="4.95"></feGaussianBlur>
              <feComposite in2="hardAlpha" operator="out"></feComposite>
              <feColorMatrix values="0 0 0 0 0.654902 0 0 0 0 0.937255 0 0 0 0 1 0 0 0 0.1 0"></feColorMatrix>
              <feBlend
                in2="BackgroundImageFix"
                result="effect1_dropShadow_20_200"
              ></feBlend>
              <feBlend
                in="SourceGraphic"
                in2="effect1_dropShadow_20_200"
                result="shape"
              ></feBlend>
              <feColorMatrix
                in="SourceAlpha"
                result="hardAlpha"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              ></feColorMatrix>
              <feOffset dy="8.002"></feOffset>
              <feGaussianBlur stdDeviation="5.15"></feGaussianBlur>
              <feComposite
                in2="hardAlpha"
                k2="-1"
                k3="1"
                operator="arithmetic"
              ></feComposite>
              <feColorMatrix values="0 0 0 0 0.654902 0 0 0 0 0.937255 0 0 0 0 1 0 0 0 1 0"></feColorMatrix>
              <feBlend in2="shape" result="effect2_innerShadow_20_200"></feBlend>
            </filter>
            <radialGradient
              id="paint0_radial_20_200"
              cx="0"
              cy="0"
              r="1"
              gradientTransform="rotate(90 13.189 14.889) scale(53.1466)"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#F2F6FC"></stop>
              <stop offset="1" stopColor="#F2F6FC" stopOpacity="0.5"></stop>
            </radialGradient>
          </defs>
        </svg>
      );
}

export default PortfolioOversight;