import { createTheme } from "@mui/material";
import Aeonik from "@base/fonts/Aeonik_Regular.woff2";
import AeonikMedium from "@base/fonts/Aeonik_Medium.woff2";

/**
 * xs, extra-small: 0px
 * sm, small: 600px
 * md, medium: 900px
 * lg, large: 1200px
 * xl, extra-large: 1536px
 */


let theme = createTheme({
  palette: {
    background: {
      default: "#0A0A0D"
    },
    text: {
      primary: "#F2F6FC",
      secondary: "rgba(242, 246, 252, 0.8)"
    },
    primary: {
      light: '#F2F6FC',
      main: '#F2F6FC',
      dark: '#F2F6FC',
      contrastText: '#0A0A0D',
    },
    secondary: {
      light: '#A7EFFF',
      main: '#A7EFFF',
      dark: '#A7EFFF',
      contrastText: '#000',
    },
  },
  typography: {
    allVariants: {
      fontFamily: 'Aeonik',
    },
  },
});


theme = createTheme(theme, {
  typography: {
    h1: {
      fontSize: '2.875rem',
      lineHeight: '2.731rem',
      fontWeight: '500',
      color: theme.palette.text.primary,
      [theme.breakpoints.up('sm')]: {
        fontSize: '4.25rem',
        lineHeight: '4.025rem',
      },
      [theme.breakpoints.up('md')]: {
        fontSize: '5rem',
        lineHeight: '4.75rem',
      },
    },
    h2: {
      fontSize: '2.25rem',
      lineHeight: '2.138rem',
      fontWeight: '500',
      color: theme.palette.text.primary,
      [theme.breakpoints.up('sm')]: {
        fontSize: '2.625rem',
        lineHeight: '2.494rem',
      },
      [theme.breakpoints.up('md')]: {
        fontSize: '3.375rem',
        lineHeight: '3.206rem',
      },
    },
    h3: {
      fontSize: '1.625rem',
      lineHeight: '1.788rem',
      fontWeight: '500',
      color: theme.palette.text.primary,
      [theme.breakpoints.up('sm')]: {
        fontSize: '1.688rem',
        lineHeight: '1.856rem',
      },
      [theme.breakpoints.up('md')]: {
        fontSize: '2.125rem',
        lineHeight: '2.338rem',
      },
    },
    h4: {
      fontSize: '1.25rem',
      lineHeight: '1.188rem',
      fontWeight: '500',
      color: theme.palette.text.primary,
      marginBottom: '0.5rem'
    },
    body1: {
      fontSize: '1rem',
      lineHeight: '1.4rem',
      fontWeight: '400',
      color: theme.palette.text.secondary,
      [theme.breakpoints.up('sm')]: {
        fontSize: '1rem',
        lineHeight: '1.4rem',
      },
      [theme.breakpoints.up('md')]: {
        fontSize: '1.25rem',
        lineHeight: '1.575rem',
      },
    },
    body2: {
      fontSize: '1rem',
      lineHeight: '1.4rem',
      fontWeight: '400',
      color: theme.palette.text.secondary,
    }
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
            @font-face {
              font-family: 'Aeonik';
              font-style: normal;
              font-display: swap;
              font-weight: 400;
              src: local('Aeonik'), local('Aeonik-Regular'), url(${Aeonik}) format('woff2');
              unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
            },
            @font-face {
              font-family: 'Aeonik';
              font-style: normal;
              font-display: swap;
              font-weight: 500;
              src: local('Aeonik'), local('Aeonik-Medium'), url(${AeonikMedium}) format('woff2');
              unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
            }
          `,
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          '&input::placeholder': {
            fontSize: '1rem',
            lineHeight: '1.4rem',
            fontWeight: '400',
            [theme.breakpoints.up('sm')]: {
              fontSize: '1rem',
              lineHeight: '1.4rem',
            },
            [theme.breakpoints.up('md')]: {
              fontSize: '1.25rem',
              lineHeight: '1.575rem',
            },
          },
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none'
        }
      }
    },
    MuiButtonBase: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          fontSize: '1rem',
          lineHeight: '1.4rem',
          fontWeight: '500',
          letterSpacing: '0em !important',
          [theme.breakpoints.up('sm')]: {
            fontSize: '1rem',
            lineHeight: '1.4rem',
          },
          [theme.breakpoints.up('md')]: {
            fontSize: '1.25rem',
            lineHeight: '1.575rem',
          },

          "&.MuiButton-outlined": {
            border: '1px solid rgba(242, 246, 252, 0.15)',
            borderRadius: '5px',
            background: 'radial-gradient(64.79% 115.7% at 50% 0%, rgba(242, 246, 252, 0.1) 0%, rgba(242, 246, 252, 0.05) 100%)',
            boxShadow: '0px 1px 1px 0px rgba(242, 246, 252, 0.1) inset',
          }
        }
      }
    }
  }
});

export default theme;