import React from "react";

const MarketDynamics = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="56"
      height="56"
      fill="none"
      viewBox="0 0 56 56"
    >
      <g filter="url(#filter0_i_20_194)">
        <rect
          width="53.147"
          height="53.147"
          x="1.788"
          y="1.7"
          fill="url(#paint0_radial_20_194)"
          fillOpacity="0.1"
          rx="6"
        ></rect>
      </g>
      <rect
        width="54.147"
        height="54.147"
        x="1.288"
        y="1.2"
        stroke="#F2F6FC"
        strokeOpacity="0.1"
        rx="6.5"
      ></rect>
      <g
        fill="#fff"
        fillRule="evenodd"
        clipRule="evenodd"
        filter="url(#filter1_di_20_194)"
      >
        <path d="M43.503 43.413V23.26H38.45v20.153h5.053zM30.848 43.413v-30.28h-5.053v30.28h5.053zM18.273 43.413V33.342H13.22v10.071h5.053z"></path>
      </g>
      <defs>
        <filter
          id="filter0_i_20_194"
          width="55.147"
          height="56.147"
          x="0.788"
          y="0.7"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feBlend
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          ></feBlend>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dy="1"></feOffset>
          <feGaussianBlur stdDeviation="0.5"></feGaussianBlur>
          <feComposite
            in2="hardAlpha"
            k2="-1"
            k3="1"
            operator="arithmetic"
          ></feComposite>
          <feColorMatrix values="0 0 0 0 0.94902 0 0 0 0 0.964706 0 0 0 0 0.988235 0 0 0 0.1 0"></feColorMatrix>
          <feBlend in2="shape" result="effect1_innerShadow_20_194"></feBlend>
        </filter>
        <filter
          id="filter1_di_20_194"
          width="50.083"
          height="50.08"
          x="3.32"
          y="3.233"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset></feOffset>
          <feGaussianBlur stdDeviation="4.95"></feGaussianBlur>
          <feComposite in2="hardAlpha" operator="out"></feComposite>
          <feColorMatrix values="0 0 0 0 0.654902 0 0 0 0 0.937255 0 0 0 0 1 0 0 0 0.1 0"></feColorMatrix>
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_20_194"
          ></feBlend>
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_20_194"
            result="shape"
          ></feBlend>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dy="8.002"></feOffset>
          <feGaussianBlur stdDeviation="5.15"></feGaussianBlur>
          <feComposite
            in2="hardAlpha"
            k2="-1"
            k3="1"
            operator="arithmetic"
          ></feComposite>
          <feColorMatrix values="0 0 0 0 0.654902 0 0 0 0 0.937255 0 0 0 0 1 0 0 0 1 0"></feColorMatrix>
          <feBlend in2="shape" result="effect2_innerShadow_20_194"></feBlend>
        </filter>
        <radialGradient
          id="paint0_radial_20_194"
          cx="0"
          cy="0"
          r="1"
          gradientTransform="rotate(90 13.33 15.03) scale(53.1466)"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F2F6FC"></stop>
          <stop offset="1" stopColor="#F2F6FC" stopOpacity="0.5"></stop>
        </radialGradient>
      </defs>
    </svg>
  );
}

export default MarketDynamics;