import React from "react";

const Medium = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="33"
            height="33"
            fill="none"
            viewBox="0 0 33 33"
        >
            <g fill="#fff" clipPath="url(#clip0_1_2824)">
                <path d="M18.49 16.465c0 4.862-3.914 8.803-8.743 8.803-4.828 0-8.743-3.942-8.743-8.803 0-4.86 3.914-8.803 8.743-8.803 4.829 0 8.743 3.942 8.743 8.803zM28.082 16.465c0 4.577-1.957 8.288-4.372 8.288-2.414 0-4.371-3.711-4.371-8.288 0-4.576 1.957-8.287 4.371-8.287 2.415 0 4.372 3.71 4.372 8.287zM32.004 16.465c0 4.1-.688 7.424-1.537 7.424-.85 0-1.537-3.324-1.537-7.424 0-4.1.688-7.424 1.537-7.424s1.537 3.323 1.537 7.424z"></path>
            </g>
            <defs>
                <clipPath id="clip0_1_2824">
                    <path
                        fill="#fff"
                        d="M0 0H32V32H0z"
                        transform="translate(.504 .465)"
                    ></path>
                </clipPath>
            </defs>
        </svg>
    );
}

export default Medium;