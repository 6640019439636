import FeaturesDesktopAnalytics from '@assets/ourFeatures/FeaturesDesktopAnalytics.png';
import FeaturesDesktopOversight from "@assets/ourFeatures/FeaturesDesktopOversight.png";
import FeaturesMobileOversight from "@assets/ourFeatures/FeaturesMobileOversight.png";
import FeaturesDesktopTrading from "@assets/ourFeatures/FeaturesDesktopTrading.png";
import FeaturesMobileTrading from "@assets/ourFeatures/FeaturesMobileTrading.png";
import FeaturesDesktopProtection from "@assets/ourFeatures/FeaturesDesktopProtection.png";
import FeaturesMobileProtection from "@assets/ourFeatures/FeaturesMobileProtection.png";
import useScreenMode from "@base/hooks/useScreenMode";
import { Grid, Typography } from "@mui/material";

const OurFeatures = () => {
    const { isPhone } = useScreenMode();


    return <>
        {!isPhone ? <Grid container rowGap={2} sx={{ paddingX: { lg: '150px', xs: '20px' } }} mt={{ lg: 20, xs: 10 }}>
            <Grid item xs={12} mb={2}>
                <Typography variant="h2" sx={{ textAlign: 'center' }}>Our Features</Typography>
                <Typography variant="body1" sx={{ textAlign: 'center' }}>Access comprehensive metrics and insights to make informed decisions.</Typography>
            </Grid>
            <Grid
                container
                item
                xs={12}
                sx={{
                    border: '1px solid rgba(242, 246, 252, 0.15)',
                    borderRadius: '5px',
                    background: 'radial-gradient(64.79% 115.7% at 50% 0%, rgba(242, 246, 252, 0.1) 0%, rgba(242, 246, 252, 0.05) 100%)',
                    boxShadow: '0px 1px 1px 0px rgba(242, 246, 252, 0.1) inset'
                }}
            >
                <Grid item xs={6} sx={{
                    paddingX: '4rem',
                    paddingY: '2.5rem',
                }}>
                    <Typography variant="h3" mb={1.5}>Comprehensive Portfolio Oversight</Typography>
                    <Typography variant="body1">Get a bird's-eye view of your assets across exchanges and optimize your portfolio from one powerful interface.</Typography>
                </Grid>
                <Grid item xs={6} display='flex' justifyContent='flex-end'>
                    <img src={FeaturesDesktopOversight} alt='Comprehensive Portfolio Oversight' style={{ width: '100%' }} />
                </Grid>
            </Grid>
            <Grid container item xs={12} justifyContent='space-between'>
                <Grid
                    item
                    container
                    xs={5.9}
                    sx={{
                        border: '1px solid rgba(242, 246, 252, 0.15)',
                        borderRadius: '5px',
                        background: 'radial-gradient(64.79% 115.7% at 50% 0%, rgba(242, 246, 252, 0.1) 0%, rgba(242, 246, 252, 0.05) 100%)',
                        boxShadow: '0px 1px 1px 0px rgba(242, 246, 252, 0.1) inset'
                    }}
                >
                    <Grid item xs={12}

                    >
                        <img src={FeaturesDesktopAnalytics} alt='Real-Time Analytics and Insights' style={{ width: '100%' }} />
                    </Grid>
                    <Grid item xs={12} sx={{
                        paddingX: '4rem',
                        paddingY: '2.5rem',
                    }}
                    >
                        <Typography variant="h3" mb={1.5}>Real-Time Analytics and Insights</Typography>
                        <Typography variant="body1">Make informed decisions with live data streaming, in-depth analytics, and trend tracking at your fingertips.</Typography>
                    </Grid>
                </Grid>

                <Grid
                    item
                    container
                    xs={5.9}
                    sx={{
                        border: '1px solid rgba(242, 246, 252, 0.15)',
                        borderRadius: '5px',
                        background: 'radial-gradient(64.79% 115.7% at 50% 0%, rgba(242, 246, 252, 0.1) 0%, rgba(242, 246, 252, 0.05) 100%)',
                        boxShadow: '0px 1px 1px 0px rgba(242, 246, 252, 0.1) inset'
                    }}
                >
                    <Grid item xs={12} sx={{
                        paddingX: '4rem',
                        paddingY: '2.5rem',
                    }}
                    >
                        <Typography variant="h3" mb={1.5}>Seamless Trading Experience</Typography>
                        <Typography variant="body1">Swap between exchanges with ease, manage orders, and trade without skipping a beat, no matter where your assets lie.</Typography>
                    </Grid>
                    <Grid item xs={12} display='flex' alignItems='flex-end'>
                        <img src={FeaturesDesktopTrading} alt='Seamless Trading Experience' style={{ width: '100%' }} />
                    </Grid>
                </Grid>
            </Grid>

            <Grid
                container
                item
                xs={12}
                sx={{
                    border: '1px solid rgba(242, 246, 252, 0.15)',
                    borderRadius: '5px',
                    background: 'radial-gradient(64.79% 115.7% at 50% 0%, rgba(242, 246, 252, 0.1) 0%, rgba(242, 246, 252, 0.05) 100%)',
                    boxShadow: '0px 1px 1px 0px rgba(242, 246, 252, 0.1) inset'
                }}
            >
                <Grid item xs={6} display='flex' justifyContent='flex-start'>
                    <img src={FeaturesDesktopProtection} alt='Fortress-Level Protection' style={{ width: '100%' }} />
                </Grid>
                <Grid item xs={6} sx={{
                    paddingX: '4rem',
                    paddingY: '2.5rem',
                }}>
                    <Typography variant="h3" mb={1.5}>Fortress-Level Protection, On Your Terms</Typography>
                    <Typography variant="body1">Hypertech ensures your trading is shielded by storing API keys directly in your browser, encrypted twice for extra safety. We prioritize your autonomy — never accessing your funds, only empowering your trading decisions.</Typography>
                </Grid>
            </Grid>

        </Grid> : <Grid container rowGap={1} mt={10}>
            <Grid item xs={12} mb={2}>
                <Typography variant="h2" sx={{ textAlign: 'center' }}>Our Features</Typography>
                <Typography variant="body1" sx={{ textAlign: 'center' }}>Access comprehensive metrics and insights to make informed decisions.</Typography>
            </Grid>

            <Grid
                container
                item
                xs={12}
                sx={{
                    border: '1px solid rgba(242, 246, 252, 0.15)',
                    borderRadius: '5px',
                    background: 'radial-gradient(64.79% 115.7% at 50% 0%, rgba(242, 246, 252, 0.1) 0%, rgba(242, 246, 252, 0.05) 100%)',
                    boxShadow: '0px 1px 1px 0px rgba(242, 246, 252, 0.1) inset'
                }}
            >
                <Grid item xs={12} display='flex' justifyContent='flex-end'>
                    <img src={FeaturesMobileOversight} alt='Comprehensive Portfolio Oversight' style={{ width: '100%' }} />
                </Grid>
                <Grid item xs={10} sx={{
                    paddingX: '1.688rem',
                    paddingY: '1.188rem',
                }}>
                    <Typography variant="h3" mb={1.5}>Comprehensive Portfolio Oversight</Typography>
                    <Typography variant="body1">Get a bird's-eye view of your assets across exchanges and optimize your portfolio from one powerful interface.</Typography>
                </Grid>
            </Grid>

            <Grid
                container
                item
                xs={12}
                sx={{
                    border: '1px solid rgba(242, 246, 252, 0.15)',
                    borderRadius: '5px',
                    background: 'radial-gradient(64.79% 115.7% at 50% 0%, rgba(242, 246, 252, 0.1) 0%, rgba(242, 246, 252, 0.05) 100%)',
                    boxShadow: '0px 1px 1px 0px rgba(242, 246, 252, 0.1) inset'
                }}
            >
                <Grid item xs={12} display='flex' justifyContent='flex-end'>
                    <img src={FeaturesDesktopAnalytics} alt='Real-Time Analytics and Insights' style={{ width: '100%' }} />
                </Grid>
                <Grid item xs={10} sx={{
                    paddingX: '1.688rem',
                    paddingY: '1.188rem',
                }}>
                    <Typography variant="h3" mb={1.5}>Real-Time Analytics and Insights</Typography>
                    <Typography variant="body1">Make informed decisions with live data streaming, in-depth analytics, and trend tracking at your fingertips.</Typography>
                </Grid>
            </Grid>

            <Grid
                container
                item
                xs={12}
                sx={{
                    border: '1px solid rgba(242, 246, 252, 0.15)',
                    borderRadius: '5px',
                    background: 'radial-gradient(64.79% 115.7% at 50% 0%, rgba(242, 246, 252, 0.1) 0%, rgba(242, 246, 252, 0.05) 100%)',
                    boxShadow: '0px 1px 1px 0px rgba(242, 246, 252, 0.1) inset'
                }}
            >
                <Grid item xs={12} display='flex' justifyContent='flex-end'>
                    <img src={FeaturesMobileTrading} alt='Seamless Trading Experience' style={{ width: '100%' }} />
                </Grid>
                <Grid item xs={10} sx={{
                    paddingX: '1.688rem',
                    paddingY: '1.188rem',
                }}>
                    <Typography variant="h3" mb={1.5}>Seamless Trading Experience</Typography>
                    <Typography variant="body1">Swap between exchanges with ease, manage orders, and trade without skipping a beat, no matter where your assets lie.</Typography>
                </Grid>
            </Grid>

            <Grid
                container
                item
                xs={12}
                sx={{
                    border: '1px solid rgba(242, 246, 252, 0.15)',
                    borderRadius: '5px',
                    background: 'radial-gradient(64.79% 115.7% at 50% 0%, rgba(242, 246, 252, 0.1) 0%, rgba(242, 246, 252, 0.05) 100%)',
                    boxShadow: '0px 1px 1px 0px rgba(242, 246, 252, 0.1) inset'
                }}
            >
                <Grid item xs={12} display='flex' justifyContent='flex-end'>
                    <img src={FeaturesMobileProtection} alt='Fortress-Level Protection' style={{ width: '100%' }} />
                </Grid>
                <Grid item xs={10} sx={{
                    paddingX: '1.688rem',
                    paddingY: '1.188rem',
                }}>
                    <Typography variant="h3" mb={1.5}>Fortress-Level Protection, On Your Terms</Typography>
                    <Typography variant="body1">Hypertech ensures your trading is shielded by storing API keys directly in your browser, encrypted twice for extra safety. We prioritize your autonomy — never accessing your funds, only empowering your trading decisions.</Typography>
                </Grid>
            </Grid>


        </Grid>}
    </>;

}

export default OurFeatures;