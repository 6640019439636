import { Grid, Typography } from "@mui/material";
import CrossExchangeImage from "@assets/CrossExchangeImage"
import CrossExchangeImageTablet from "@assets/CrossExchangeImageTablet"
import CrossExchangeImagePhone from "@assets/CrossExchangeImagePhone"
import useScreenMode from "@base/hooks/useScreenMode";
import PortfolioOversight from "@assets/PortfolioOversight";
import MarketDynamics from "@assets/MarketDynamics";
import SeamlessTrading from "@assets/SeamlessTrading";
import PerformanceAnalyze from "@assets/PerformanceAnalyze";

const CrossExchange = () => {
    const { isPhone, isDesktop, isTablet } = useScreenMode();

    return (
        <Grid container mt={{ lg: 20, xs: 10 }}>
            <Grid container columnSpacing={2.5}>
                <Grid item xs={12} md={6} sm={12} container justifyContent='space-between'>
                    <Grid item mb={3} md={10}>
                        <Typography variant="h2" mb={2}>Your Cross-Exchange Crypto Command Center</Typography>
                        <Typography variant="body1">Execute trades, analyze patterns, and optimize strategies across multiple exchanges, all from Hypertech's unified platform.</Typography>
                    </Grid>

                    {isDesktop ? <Grid item display='flex' alignItems='flex-end' md={10}>
                        <CrossExchangeCommandCenter />
                    </Grid> : null}
                </Grid>

                <Grid item xs={12} md={6} sm={12} display='flex' justifyContent={{sx: 'center', sm: 'center', md: 'flex-end'}}>
                    {isDesktop && <CrossExchangeImage />}
                    {isPhone && <CrossExchangeImagePhone />}
                    {isTablet && <CrossExchangeImageTablet />}
                </Grid>


                {!isDesktop ? <Grid item display='flex' alignItems='flex-end'>
                    <CrossExchangeCommandCenter />
                </Grid> : null}
            </Grid>

        </Grid >
    );
};


const CrossExchangeCommandCenter = () => {


    return (<Grid container columnSpacing={5} rowSpacing={{ xs: 2, md: 5 }} mt={6}>
        <Grid item xs={12} md={6} sm={6} container flexDirection='row'>
            <Grid container item alignItems='center' columnSpacing={1.5}>
                <Grid item>
                    <PortfolioOversight />
                </Grid>
                <Grid item>
                    <Typography variant="h4">Portfolio Oversight</Typography>
                    <Typography variant="body2">Track your assets</Typography>
                </Grid>
            </Grid>
        </Grid>
        <Grid item xs={12} md={6} sm={6} container flexDirection='row'>
            <Grid container item alignItems='center' columnSpacing={1.5}>
                <Grid item>
                    <MarketDynamics />
                </Grid>
                <Grid item>
                    <Typography variant="h4">Market Dynamics</Typography>
                    <Typography variant="body2">Real-time data</Typography>
                </Grid>
            </Grid>
        </Grid>
        <Grid item xs={12} md={6} sm={6} container flexDirection='row'>
            <Grid container item alignItems='center' columnSpacing={1.5}>
                <Grid item>
                    <SeamlessTrading />
                </Grid>
                <Grid item>
                    <Typography variant="h4">Seamless Trading</Typography>
                    <Typography variant="body2">Inter-exchange trading</Typography>
                </Grid>
            </Grid>
        </Grid >
        <Grid item xs={12} md={6} sm={6} container flexDirection='row' >
            <Grid container item alignItems='center' columnSpacing={1.5}>
                <Grid item>
                    <PerformanceAnalyze />
                </Grid>
                <Grid item>
                    <Typography variant="h4">Performance Analysis</Typography>
                    <Typography variant="body2">Full trade analytics</Typography>
                </Grid>
            </Grid>
        </Grid>
    </Grid >
    );
}

export default CrossExchange;