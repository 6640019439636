import { Grid, useTheme } from "@mui/material";
import Logo from "@assets/Logo";
import { PAGE_MAX_WIDTH } from "@base/constants/constants";


const AppAppBar = () => {
    const theme = useTheme();

    return (
        <Grid container sx={{
            flexDirection: 'row',
            justifyContent: 'center',
            height: '60px',
            backgroundColor: theme.palette.background.default,
            borderBottomColor: 'rgba(242, 246, 252, 0.1)',
            borderBottomWidth: '1px',
            borderBottomStyle: 'solid',
            padding: '17px',
        }}>
            <Grid container className="content" sx={{
                maxWidth: PAGE_MAX_WIDTH,
                alignItems: 'center',
                justifyContent: 'space-between',
            }}>
                <Grid xs='auto' item sx={{
                    display: 'flex',
                    alignItems: 'center',
                }}>
                    <Logo />
                </Grid>
                <Grid xs='auto' container item sx={{
                    display: 'flex',
                    flexDirection: 'row'
                }}>
                    {/**
                    <Typography>Features</Typography>
                    <Typography>Integrations</Typography>
                    <Typography>Company</Typography>
            */}
                </Grid>
            </Grid>
        </Grid>
    );
};

export default AppAppBar;