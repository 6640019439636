import React from "react";

const SeamlessTrading = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="56"
      height="56"
      fill="none"
      viewBox="0 0 56 56"
    >
      <g filter="url(#filter0_i_20_261)">
        <rect
          width="53.147"
          height="53.147"
          x="1.073"
          y="1.7"
          fill="url(#paint0_radial_20_261)"
          fillOpacity="0.1"
          rx="6"
        ></rect>
      </g>
      <rect
        width="54.147"
        height="54.147"
        x="0.573"
        y="1.2"
        stroke="#F2F6FC"
        strokeOpacity="0.1"
        rx="6.5"
      ></rect>
      <g fill="#fff" filter="url(#filter1_di_20_261)">
        <path d="M17.557 15.133H42.788v12.14h-5.053v-7.087H17.557v-5.053zM12.504 41.413v-12.14h5.053v7.087h20.178v5.053H12.504z"></path>
      </g>
      <defs>
        <filter
          id="filter0_i_20_261"
          width="55.147"
          height="56.147"
          x="0.073"
          y="0.7"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feBlend
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          ></feBlend>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dy="1"></feOffset>
          <feGaussianBlur stdDeviation="0.5"></feGaussianBlur>
          <feComposite
            in2="hardAlpha"
            k2="-1"
            k3="1"
            operator="arithmetic"
          ></feComposite>
          <feColorMatrix values="0 0 0 0 0.94902 0 0 0 0 0.964706 0 0 0 0 0.988235 0 0 0 0.1 0"></feColorMatrix>
          <feBlend in2="shape" result="effect1_innerShadow_20_261"></feBlend>
        </filter>
        <filter
          id="filter1_di_20_261"
          width="50.083"
          height="46.08"
          x="2.604"
          y="5.233"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset></feOffset>
          <feGaussianBlur stdDeviation="4.95"></feGaussianBlur>
          <feComposite in2="hardAlpha" operator="out"></feComposite>
          <feColorMatrix values="0 0 0 0 0.654902 0 0 0 0 0.937255 0 0 0 0 1 0 0 0 0.1 0"></feColorMatrix>
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_20_261"
          ></feBlend>
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_20_261"
            result="shape"
          ></feBlend>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dy="8.002"></feOffset>
          <feGaussianBlur stdDeviation="5.15"></feGaussianBlur>
          <feComposite
            in2="hardAlpha"
            k2="-1"
            k3="1"
            operator="arithmetic"
          ></feComposite>
          <feColorMatrix values="0 0 0 0 0.654902 0 0 0 0 0.937255 0 0 0 0 1 0 0 0 1 0"></feColorMatrix>
          <feBlend in2="shape" result="effect2_innerShadow_20_261"></feBlend>
        </filter>
        <radialGradient
          id="paint0_radial_20_261"
          cx="0"
          cy="0"
          r="1"
          gradientTransform="rotate(90 12.973 14.673) scale(53.1466)"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F2F6FC"></stop>
          <stop offset="1" stopColor="#F2F6FC" stopOpacity="0.5"></stop>
        </radialGradient>
      </defs>
    </svg>
  );
}

export default SeamlessTrading;