import { Grid, useTheme } from "@mui/material";
import animation from '@assets/loader.gif';
import animationDesktop from '@assets/loader_desktop.gif';

const FullScreenLoader = () => {
    const theme = useTheme();

    return (<Grid container
        sx={{
            minHeight: '100vh',
            maxHeight: '100vh',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundImage: `url(${animation})`,
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            [theme.breakpoints.up('sm')]: {
                backgroundImage: `url(${animationDesktop})`,
            }
        }}
    >

    </Grid>)
}

export default FullScreenLoader;