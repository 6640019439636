import React from "react";

const Twitter = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="33"
            height="33"
            fill="none"
            viewBox="0 0 33 33"
        >
            <path
                fill="#fff"
                d="M12.349 24.944a11.731 11.731 0 0012.124-11.658 8.575 8.575 0 001.568-3.01.467.467 0 00-.657-.54 1.992 1.992 0 01-2.289-.403 4.123 4.123 0 00-5.914-.18 4.378 4.378 0 00-1.218 4.133c-3.55.212-5.988-1.473-7.938-3.784a.456.456 0 00-.795.255 10.259 10.259 0 004.875 10.65 7.133 7.133 0 01-4.737 2.417.476.476 0 00-.149.89 11.659 11.659 0 005.13 1.23z"
            ></path>
        </svg>
    );
}

export default Twitter;