import Discord from "@assets/Discord";
import Logo from "@assets/Logo";
import Medium from "@assets/Medium";
import Twitter from "@assets/Twitter";
import MainLayout from "@components/MainLayout";
import { Grid, Typography } from "@mui/material";


const Footer = () => {

    return (
        <Grid
            container
            sx={{
                borderTop: '1px solid rgba(167, 239, 255, 0.8)',
                boxShadow: '0px -11px 75.3px 0px rgba(167, 239, 255, 0.25)',
                paddingY: { lg: '4rem', xs: '2rem' },
            }}
            mt={{ lg: 20, xs: 10 }}
        >
            <MainLayout>
                <Grid container justifyContent='space-between'>
                    <Grid item>
                        <Logo />
                        <Typography variant="body2">@ {(new Date().getFullYear())} Hypertech. All rights reserved.</Typography>
                    </Grid>
                    <Grid item>
                        <a
                            target='_blank'
                            rel='noopener noreferrer'
                            href="https://twitter.com/hypertechorg">
                            <Twitter />
                        </a>
                        <a
                            target='_blank'
                            rel='noopener noreferrer'
                            style={{ marginLeft: '8px' }}
                            href="https://discord.gg/9tBc53dQ">
                            <Discord />
                        </a>
                        <a
                            target='_blank'
                            rel='noopener noreferrer'
                            style={{ marginLeft: '8px' }}
                            href="https://medium.com/@hypertech">
                            <Medium />
                        </a>
                    </Grid>
                </Grid>
            </MainLayout >
        </Grid >
    );
}

export default Footer;


// box-shadow: 0px -79px 183.3px 0px rgba(167, 239, 255, 0.12);

// box-shadow: 0px -11px 75.3px 0px rgba(167, 239, 255, 0.25);
