import './App.css';
import { CssBaseline, ThemeProvider } from '@mui/material';
import AppAppBar from './components/AppBar/AppAppBar';
import Home from './pages/Home/Home';
import theme from './theme/theme';
import { useEffect, useState } from 'react';
import FullScreenLoader from '@components/FullScreenLoader';
import Footer from '@components/Footer/Footer';

const App = () => {

  const [firstVisit, setFirstVisit] = useState(
    localStorage.getItem('visited') !== 'true'
  );

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (firstVisit) {
        setFirstVisit(false);
        localStorage.setItem('visited', 'true');
      }
    }, 4100);

    return () => clearTimeout(timeout);
  }, [firstVisit]);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {firstVisit ?
        <FullScreenLoader />
        : <>
          <AppAppBar />
          <Home />
          <Footer />
        </>}
    </ThemeProvider>

  );
}

export default App;
